import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { navigateAwayGuard } from "./base/guards/navigate-away.guard";
import { BleStatusComponent } from "./business/components/ble-status/ble-status.component";
import { OnboardingInfoComponent } from "./business/components/onboarding/onboarding-info.component";
import { JobDesignerComponent } from "./business/pages/job-designer/job-designer.component";
import { JobListComponent } from "./business/pages/job-list/job-list.component";
import { JobModeMeasurementComponent } from "./business/pages/job-mode-measurement/job-mode-measurement.component";
import { JobTemplateListComponent } from "./business/pages/job-template-list/job-template-list.component";
import { LicenseComponent } from "./business/pages/license/license.component";
import { PartDesignerComponent } from "./business/pages/part-designer/part-designer.component";
import { PartListComponent } from "./business/pages/part-list/part-list.component";
import { QuickModeComponent } from "./business/pages/quick-mode/quick-mode.component";
import { SettingsComponent } from "./business/pages/settings/settings.component";
import { TabsPage } from "./business/pages/tabs/tabs.page";

const defaultRoutes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "quick-mode"
    },
    {
        path: "quick-mode",
        component: QuickModeComponent
    },
    {
        path: "job-templates",
        component: JobTemplateListComponent
    },
    {
        path: "job-templates/:id",
        component: JobDesignerComponent,
        canDeactivate: [navigateAwayGuard]
    },
    {
        path: "parts",
        component: PartListComponent
    },
    {
        path: "parts/:id",
        component: PartDesignerComponent,
        canDeactivate: [navigateAwayGuard]
    },
    {
        path: "settings",
        component: SettingsComponent
    },
    {
        path: "jobs",
        component: JobListComponent
    },
    {
        path: "jobs/:id",
        component: JobModeMeasurementComponent,
        canDeactivate: [navigateAwayGuard]
    },
    {
        path: "ble-status",
        component: BleStatusComponent
    },
    {
        path: "license",
        component: LicenseComponent
    },
    {
        path: "**",
        redirectTo: "quick-mode"
    }
];

const routes: Routes = [
    {
        path: "",
        component: TabsPage,
        children: defaultRoutes
    },
    {
        path: "onboarding",
        component: OnboardingInfoComponent
    }
];

/**
 *
 */
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
/**
 * Module to define the app routes.
 */
export class AppRoutingModule {
}
